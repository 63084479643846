/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
const mock = [
	{ 
		title:`High performance, low resources`,
		primary:'',
		desc:`Data plane proxy Pipy is developed in <b>C++</b>. It uses an asynchronous network framework; allocated resources are pooled and recycled. Provides high performance with a shallow resource footprint, suitable for large-scale, high-density cloud scenarios and edge scenarios with limited processing power and performance.`,
		feature:'/img/osm/01.svg',
		layout:'left'
	},
	{ 
		title:`Multi-Cluster`,
		primary:'',
		desc:`With <a href="https://github.com/flomesh-io/ErieCanal" target="_blank">ErieCanal</a>, which supports Ingress, Gateway API, and cross-cluster service discovery, it provides Kubernetes intra-cluster + multi-cluster "east-west + north-south" traffic management and service governance capability. It enables cross-cluster interoperability and traffic scheduling to solve the traffic management challenges in multi-cluster use cases.`,
		feature:'/img/osm/02.svg',
		layout:'right'
	},
	{ 
		title:`ARM Ready`,
		primary:'',
		desc:`Supports <b>x86, ARM64, Longxin,</b> and <b>RISC-V</b> computing architectures to improve hardware compatibility and meet the needs of diverse computing scenarios.`,
		feature:'/img/osm/03.svg',
		layout:'left'
	},
	{ 
		title:`Easy to scale`,
		primary:'',
		desc:`The data plane uses a programmable proxy <a href="https://flomesh.io/pipy" target="_blank">Pipy</a> and a built-in JavaScript engine, which allows customization and extension of the service mesh functionality using JS syntax scripts.
<br/>The control plane is based on <a href="https://github.com/openservicemesh/osm" target="_blank">OSM (Open Service Mesh)</a> developed by Go, and the open design makes it easy to extend the functionality.`,
		feature:'/img/osm/04.svg',
		layout:'right'
	},
	{ 
		title:`Observability`,
		primary:'',
		desc:`Generates comprehensive telemetry data for network traffic within the mesh, providing non-intrusive observability for application containers, including metrics, distributed traces, and logs. Easily customize various metrics and provide multiple log collection and processing methods through the ease of scalability of the data plane.
<br/>Visual display of telemetry data is provided through the console.`,
		feature:'/img/osm/05.svg',
		layout:'left'
	},
	{ 
		title:`Multi-protocol Support`,
		primary:'',
		desc:`Pipy natively supports <b>HTTP1, HTTP2/gRPC, TCP, UDP, MQTT, Dubbo, Thrift,</b> and other protocols; the control plane currently supports <b>HTTP1, HTTP2/gRPC, TCP, UDP</b> traffic management, and more protocols can be supported through extensions.`,
		feature:'/img/osm/06.svg',
		layout:'right'
	},
	{ 
		title:`Support for SMI`,
		primary:'',
		desc:`<b>SMI (Service Mesh Interface)</b> provides a standard interface to the Kubernetes Service Mesh, a basic set of features for standard Service Mesh scenarios, the flexibility to provide new Service Mesh features, and a space for innovation in the Service Mesh technology ecosystem that is easy to use.`,
		feature:'/img/osm/07.svg',
		layout:'left'
	},
	{ 
		title:`Dashboard`,
		primary:'',
		desc:`Provides a powerful console that facilitates managing the Service Mesh and configuring service governance features in addition to rich data presentation.`,
		feature:'/img/osm/08.svg',
		layout:'right'
	},
]
const Teaser = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
	  {mock.map((item, i) => (
			<Box key={i}>
			{( item.layout=='left'||!isMd )
			? <Grid  container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-right' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'} dangerouslySetInnerHTML={{__html:item.desc}}>
					</Typography>
				  </Box>
				</Grid>
				<Grid
				  item
				  container
				  justifyContent="center"
				  alignItems="center"
				  xs={12}
				  md={6}
				>
				  <Box maxWidth={490} width={1}>
					<Box
					  component={'img'}
					  src={item.feature}
					  width={1}
					  height={1}
						alt={item.title}
					  sx={{
						filter:
						  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
					  }}
					/>
				  </Box>
				</Grid>
			  </Grid>
			: <Grid key={i} container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
					item
					container
					justifyContent="center"
					alignItems="center"
					xs={12}
					md={6}
				  >
					<Box maxWidth={490} width={1}>
					<Box
					  component={'img'}
					  src={item.feature}
					  width={1}
					  height={1}
						alt={item.title}
					  sx={{
						filter:
						  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
					  }}
					/>
					</Box>
				</Grid>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-left' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'} dangerouslySetInnerHTML={{__html:item.desc}}>
					</Typography>
				  </Box>
				</Grid>
			  </Grid>
				}
				</Box>
      ))}
    </Box>
  );
};

export default Teaser;
