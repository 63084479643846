import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink"
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const mock = [
  {
    title: 'FSM.',
		logo:['/img/fsm.jpg','/img/fsm.jpg'],
    desc: [
			`<b>Flomesh Service Mesh (FSM)</b> stands out as a <b>cloud-native</b> service mesh solution renowned for its lightweight design and adaptability, catering to both <b>x86</b> and <b>ARM</b> architectures. <b>FSM</b> excels in seamlessly managing traffic for deployments while ensuring the security of <b>service-to-service</b> communication through mutual TLS. Moreover, it empowers users to define and enforce finely-tuned access control policies, providing valuable insights into application metrics for effective debugging and service monitoring, thanks to its robust observability features.
What sets <b>FSM</b> apart is its effortless integration with external certificate management services through a pluggable interface. Simplifying the onboarding process for applications onto the mesh, <b>FSM</b> achieves this through the automatic sidecar injection of the <a href="https://flomesh.io/pipy" target="_blank">Pipy</a> proxy. 
Noteworthy is <b>FSM</b>'s support for Multi-cluster <b>Kubernetes</b>, facilitated by the implementation of the <a href="https://github.com/kubernetes-sigs/mcs-api" target="_blank">MCS-API</a> and
support of <b>Kubernetes Gateway API</b> via its own implementation of <a href="https://gateway-api.sigs.k8s.io/" target="_blank">Kubernetes Gateway API</a> specification.
<b>FSM</b> package includes built-in controllers for <b>Ingress/Egress</b> and the <b>Gateway API</b>, along with support for protocols such as <b>MQTT</b>.`
		],
		btn:'FSM Home',
		path:'/fsm',
		prefix: '',
		words: [
			'Open source',
			'Supports Kubernetes Gateway Api',
			'K8s Multi-cluster discovery & access',
			'Community driven',
			'Easy to install',
			'Easy to extend',
			'ARM Ready',
			'Built for Cloud and Edge',
			'Optimized for resource constraint environments',
			'Works on multiple architectures',
			'Programmable proxy Pipy built-in',
			'Out of the box support for multiple protocols',
			'Full observability support',
			'Comes with Dashboard',
		]
  }
];
const Hero = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0,
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
      }}
    >
      <Box paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
        <Container>
					{mock.map((item, i) => (
						<Box key={i}>
							<Box maxWidth={{ xs: 1, sm: '50%' }} sx={{
								minHeight: '250px'
							}}>
								<Typography
									variant="h3"
									color="text.primary"
									gutterBottom
									sx={{
										fontWeight: 700,
									}}
								>
									{ item.logo && (
										<Box
											display={'inline-block'}
											component={'span'}
											height={{ xs: 70, md: 70 }}
											sx={{
												verticalAlign: 'middle',
												position: 'relative',
												marginRight: '20px'
											}}
										>
											<Box
												component={'img'}
												sx={{
													verticalAlign: 'middle'
												}}
												src={
													mode === 'light'
														? item.logo[0]
														: item.logo[1]
												}
												height={1}
											/>
										</Box>
									)}
									<Box
										display={'inline-block'}
										sx={{
											verticalAlign: 'middle'
										}}
									>
										{item.title}
									</Box>
									<br />
									{item.prefix?(
										<Typography
											color="text.primary"
											component={'span'}
											variant="h5"
											sx={{
												marginRight: '10px',
											}}
										>
										{item.prefix}
										</Typography>
									):null}
									{item.words?(
										<Typography
											color={'primary'}
											component={'span'}
											variant="h5"
										>
											<Typed
												strings={item.words}
												typeSpeed={80}
												loop={true}
											/>
										</Typography>
									):null}
								</Typography>
								{item.desc.map((desc, j) => (
									<Typography
										key={j}
										variant="h7"
										component="p"
										color="text.secondary"
										sx={{ fontWeight: 400,marginBottom:'15px' }}
										dangerouslySetInnerHTML={{__html:desc}}
									>
									</Typography>
								))}
								
								<Box
								  display="flex"
								  flexDirection={{ xs: 'column', sm: 'row' }}
								  alignItems={{ xs: 'stretched', sm: 'flex-start' }}
								  marginTop={4}
								>
								  <Button
								    component="a"
								    variant="contained"
								    color="primary"
								    size="large"
								    fullWidth={isMd ? false : true}
										target="_blank"
								    href="https://github.com/flomesh-io/fsm"
								  >
								    Github
								  </Button>
								  <Box
								    marginTop={{ xs: 2, sm: 0 }}
								    marginLeft={{ sm: 2 }}
								    width={{ xs: '100%', md: 'auto' }}
								  >
								    <Button
								      component="a"
								      href="https://fsm-docs.flomesh.io"
											target="_blank"
								      variant="outlined"
								      color="primary"
								      size="large"
								      fullWidth={isMd ? false : true}
								    >
								      Documentation
								    </Button>
								  </Box>
								</Box>
							</Box>
						</Box>
					))}
        </Container>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Box
            display={'flex'}
            width={'50rem'}
            left={'50%'}
            top={0}
            position={'absolute'}
            sx={{ transform: 'translate3d(10%, 40%, 0)' }}
          >
		  
		  <Box
		      component={LazyLoadImage}
		      effect="blur"
					alt="high performance web server,programmable ingress,programmable egress,software loadbalancer,general purpose sidecar"
		      src={
		        theme.palette.mode === 'dark' ? '/img/osm/screen-img.png' : '/img/osm/screen-img.png'
		      }
		      width={620}
		      maxWidth={620}
		    />
          </Box>
        </Box>
      </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
